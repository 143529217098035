import DishModal from './DishModal';
import { connect } from 'react-redux';
import { AppState } from '../../../../state/createStore';
import { bindActionCreators, Dispatch } from 'redux';
import {
  addPendingOrderItemToCart,
  clickAddItemToCart,
  setPendingOrderItem,
} from '../../../../state/cart/cart.actions';
import moment from 'moment-timezone';
import { withTranslation } from '@wix/yoshi-flow-editor';

function mapStateToProps(state: AppState) {
  const { isMobile } = state.platformParams;
  const platform = isMobile ? 'mobileweb' : 'web';
  const { pendingItemIndex } = state.cart;
  const { locale, currency } = state.session.restaurant;

  return {
    menu: state.session.menu,
    locale,
    currency,
    dispatchType: state.checkout.dispatch.type,
    dispatchTime: moment(state.checkout.dispatch.time).tz(state.session.restaurant.timezone),
    itemsHash: state.session.itemsHash,
    pendingOrderItem: state.cart.pendingOrderItem,
    platform,
    isEditingItemFromTheCart: pendingItemIndex !== undefined,
    showLabels: state.platformParams.settings.showLabels,
    isMobile,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      setPendingOrderItem,
      addPendingOrderItemToCart,
      clickAddItemToCart,
    },
    dispatch,
  );
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(DishModal));
