import React, { useState } from 'react';
import TextField from '../../components/TextField';
import NumberFormatUtils from '@wix/wixrest-utils/dist/NumberFormatUtils';
import { useTranslation } from '@wix/yoshi-flow-editor';

interface CurrencyInputProps {
  onChange: (e: number) => void;
  value: number;
  locale: string;
  dataHook?: string;
  className?: string;
  currency?: string;
  label?: string;
}

const CurrencyInput: React.FC<CurrencyInputProps> = (props: CurrencyInputProps) => {
  const { t } = useTranslation();
  const formatStringToNumber = (string: string) => {
    return NumberFormatUtils.parse(string, props.locale);
  };
  const formatNumberToString = (number: number) => {
    return NumberFormatUtils.format(number, null, props.locale);
  };

  const { value = '' } = props;
  const [formattedInput, setFormattedInput] = useState(formatNumberToString(Number(value) / 100));

  const isValidInput = (string: string) => {
    let isValid = false;
    const inputNumericValue = formatStringToNumber(string);
    if (string === '' || (!isNaN(inputNumericValue) && inputNumericValue >= 0)) {
      isValid = true;
    }
    return isValid;
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '') {
      setFormattedInput('0');
      props.onChange(0);
      return;
    }

    if (!isValidInput(e.target.value)) {
      return;
    }

    const inputString = e.target.value;
    const isDecimal = inputString.slice(-1) === '.' || inputString.slice(-1) === ',';
    const isZero = inputString.slice(-2) === '.0' || inputString.slice(-2) === ',0';
    const numericInput = formatStringToNumber(inputString);
    const newFormattedInput = isDecimal || isZero ? inputString : formatNumberToString(numericInput);
    props.onChange(numericInput);
    setFormattedInput(newFormattedInput);
  };

  return (
    <TextField
      autoFocus
      data-hook={props.dataHook}
      className={props.className}
      onChange={onChange}
      value={formattedInput}
      prefix={props.currency}
      label={props.label}
      maxLength={9}
      aria-label={`${t('cart_tip_leavetip_label')} ${t('cart_tip_option_custom')}`}
      prefixLabel={props.currency}
    />
  );
};

export default CurrencyInput;
