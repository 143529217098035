import React from 'react';
import { Dispatch, Store } from 'redux';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { createBrowserHistory, createMemoryHistory, History } from 'history';
import moment from 'moment-timezone';
import 'moment/locale/he';
import { createPreserveQueryHistory } from '../../../core/routing';
import { AppState } from '../../../state/createStore';
import { setupStore } from '../../../state/createWidgetStore';
import Main from '../components/Main';
import { IHostProps } from '@wix/native-components-infra/dist/src/types/types';
import HostStyleContext from '../components/HostStyleContext';
import { runSideEffectsAfterFirstRender } from '../../../state/session/session.actions';

interface WidgetWrapperProps {
  appName: string;
  mobile: boolean;
  language: string;
  appState: AppState;
  dispatch: Dispatch;
  basePath: string;
  shouldNotRenderMain: boolean;
  host: IHostProps;
}

export default class extends React.Component<WidgetWrapperProps> {
  store: Store;
  updateStore: (appState: AppState) => void;
  history: History;

  constructor(props: WidgetWrapperProps) {
    super(props);
    const { store, updateStore } = setupStore(
      {
        ...props.appState,
        platformParams: {
          ...props.appState.platformParams,
          compClassName: props.host.id,
        },
      },
      props.dispatch,
    );
    this.store = store;
    this.updateStore = updateStore;

    if (typeof location !== 'undefined') {
      const basename = props.basePath.replace(location.protocol + '//' + location.hostname, '').replace(/^\/\//, '/');
      this.history = createPreserveQueryHistory(createBrowserHistory)({ basename });
    } else {
      this.history = createPreserveQueryHistory(createMemoryHistory)();
    }

    moment.locale(props.language);
  }

  componentDidMount() {
    performance?.mark && performance.mark('restaurant-olo-client-loaded');
    this.props.dispatch(runSideEffectsAfterFirstRender());
  }

  shouldComponentUpdate(prevProps: WidgetWrapperProps) {
    return prevProps.appState !== this.props.appState;
  }

  resetURLIfEditorXPreviewModeOrDeviceChanged = (prevProps: WidgetWrapperProps) => {
    const {
      mobile,
      appState: {
        platformParams: { isEditorX, isViewer, viewMode },
      },
    } = this.props;

    // no need to handle, the editor / viewer take care of it
    if (!isEditorX || isViewer) {
      return;
    }

    const previewDeviceChanged = prevProps.mobile !== mobile;
    const viewModeChanged = prevProps.appState.platformParams.viewMode !== viewMode;

    if (viewModeChanged || previewDeviceChanged) {
      this.history.push('/');
    }
  };

  componentDidUpdate(prevProps: WidgetWrapperProps) {
    this.resetURLIfEditorXPreviewModeOrDeviceChanged(prevProps);
    this.updateStore(this.props.appState);
  }

  render() {
    return (
      <React.Fragment>
        <HostStyleContext.Provider value={this.props.host.style}>
          <Provider store={this.store}>
            <Router history={this.history}>{!this.props.shouldNotRenderMain && <Main />}</Router>
          </Provider>
        </HostStyleContext.Provider>
      </React.Fragment>
    );
  }
}
