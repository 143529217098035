import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment-timezone';
import 'moment/min/locales.min';
import { Trans, useTranslation, useBi } from '@wix/yoshi-flow-editor';
import { ButtonPriority as PRIORITY, Spinner, SectionNotification } from 'wix-ui-tpa';
import usePhoneUtils from '../../../../core/hooks/usePhoneUtils';
import usePaymentIcon from './usePaymentIcon';
import Text from '../../core-components/Text';
import dataHooks from '../../data-hooks';
import styles from './ThankYou.scss';
import ThankYouRestaurantDetails from './ThankYouRestaurantDetails';
import { ContactlessDineIn, getDisplayablePrice, Order, Payment, Restaurant } from '@wix/restaurants-client-logic';
import Button from '../Button';
import Scroller from '../Scroller';
import classNames from 'classnames';
import { getVirtualDispatchTypeFromDispatch } from '../../../../core/logic/dispatchLogic';
import ThankYouLoyaltyBox from './ThankYouLoyaltyBox';
import _ from 'lodash';
import { PartialLocation } from '../../../../core/oloApi';
import { ReactComponent as WarningSignIcon } from '../../../../assets/icons/WarningSign.svg';

export interface ThankYouProps {
  order: Order;
  locale: string;
  currency: string;
  timezone: string;
  restaurant: Restaurant;
  initCheckout: Function;
  initAddressForm: Function;
  loginMember: any;
  centsAmount: number;
  pointsRequired: number;
  currencyValue: number;
  customPointsName: string;
  isLoyaltyEarnActive?: boolean;
  isUserLoggedIn?: boolean;
  loyaltyProgramName?: string;
  loyaltyRewardId: string;
  pointsBalance?: string;
  goToLoyaltyPage: Function;
  isRewardInstalled?: boolean;
  locations: PartialLocation[];
  orderPacingLevel?: number;
  isFutureOrder?: boolean;
}

function getPaymentMethodTitle(payment: Payment) {
  if (payment.type === 'cashier') {
    return payment.creditCard ? `●●●● ${payment.creditCard.lastDigits}` : payment.paymentMethodTitle;
  }

  return 'Offline';
}

const PaymentMethod: React.FC<{ payment: Payment; locale: string }> = ({ payment, locale }) => {
  const iconSrc = usePaymentIcon(
    locale,
    payment.type === 'cashier',
    payment.type === 'cashier' ? payment.paymentMethod : undefined,
  );

  return (
    <span>
      {payment.type === 'cash' ? (
        <img
          data-hook={dataHooks.thankYouPagePaymentIcon}
          src="http://images-wixmp-6613fa290e8c1ac70a0297b6.wixmp.com/payment-methods/onboarding/png/offline.png/v1/fit/w_42,h_25/offline.png"
          alt="Payment Icon"
        />
      ) : (
        iconSrc && <img data-hook={dataHooks.thankYouPagePaymentIcon} src={iconSrc} alt="Payment Icon" />
      )}
      <Text typography="p2-m" data-hook={dataHooks.thankYouPagePaymentMethod} className={styles.paymentMethodTitle}>
        {getPaymentMethodTitle(payment)}
      </Text>
    </span>
  );
};

function getDisplayableOrderLocation(restaurant: Restaurant, locations: PartialLocation[], order: Order) {
  if (!locations) {
    return '';
  }
  const orderLocation = locations.find((l) => l.currentLocationId === order.locationId);

  // If the restaurant has multi location. Take it from the locations list
  if (orderLocation) {
    const locationLocale = orderLocation.locale;
    return orderLocation.locationName?.[locationLocale] || '';
  }

  // If the restaurant has no locations. Take it from the restaurant's default location
  if (restaurant.locationName) {
    const locationLocale = restaurant.locale;
    return restaurant.locationName?.[locationLocale] || '';
  }

  return '';
}

const ThankYou: React.FC<ThankYouProps> = ({
  order,
  locale,
  currency,
  timezone,
  restaurant,
  initAddressForm,
  initCheckout,
  loginMember,
  customPointsName,
  isLoyaltyEarnActive,
  isUserLoggedIn,
  loyaltyProgramName,
  goToLoyaltyPage,
  loyaltyRewardId,
  isRewardInstalled,
  locations,
  orderPacingLevel,
  isFutureOrder,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const PhoneUtils = usePhoneUtils();
  const biLogger = useBi();
  const resetUserOrderSession = () => {
    initAddressForm();
    initCheckout();
  };

  React.useEffect(() => {
    if (!order) {
      history.replace('/');
    }
  }, [order, history]);

  const shouldShowOrderPacingWarning = !!orderPacingLevel && !isFutureOrder;

  useEffect(() => {
    if (shouldShowOrderPacingWarning) {
      biLogger.checkoutInformativeMessage({
        checkoutStep: 'submit-success',
        messageType: 'order-pacing',
        messageValue: 'delay-alert--kitchen-is-busy',
      });
    }
  }, [shouldShowOrderPacingWarning, biLogger]);

  if (!order) {
    return <div />;
  }

  const contactlessDineInInfo: false | ContactlessDineIn | undefined =
    order.delivery.type === 'takeout' && order.delivery?.contactlessDineIn;
  const shouldDisplayDeliveryTime = !contactlessDineInInfo && order.delivery.time;
  const shouldDisplayCurbsideInfo = order.delivery.type === 'takeout' && order.delivery?.isCurbside;
  const shouldRenderFulfilledByLine = _.get(order, 'delivery.deliveryProvider.providerName', undefined);
  const dispatchType = getVirtualDispatchTypeFromDispatch(order.delivery);
  const localDeliveryTime = moment(order.delivery.time).tz(timezone).locale(locale).calendar({ sameElse: 'LLLL' });
  const orderLocationName = getDisplayableOrderLocation(restaurant, locations, order);

  const getFulfilledByLine = () => {
    const { providerIconUrl, providerName } = _.get(order, 'delivery.deliveryProvider', {});

    return (
      <div className={styles.detailWrapper}>
        <Text typography="p2-m" className={styles.capitalize} data-hook={dataHooks.thankYouPageFulfilledByTitle}>
          {t('thank_you_page_fulfilled_by')}
        </Text>
        <span>
          {providerIconUrl && (
            <img
              data-hook={dataHooks.thankYouPagePartnerIcon}
              src={providerIconUrl}
              alt="partner Icon"
              width="21"
              height="21"
            />
          )}
          <Text
            typography="p2-m"
            data-hook={dataHooks.thankYouPageFulfilledBy}
            className={styles.fulfilledByPartnerName}
          >
            {providerName}
          </Text>
        </span>
      </div>
    );
  };

  return (
    <Scroller name="thank-you" condition>
      <div className={styles.wrapper} data-hook={dataHooks.thankYouPage}>
        {PhoneUtils ? (
          <>
            <Text className={styles.header} typography="header-m" data-hook={dataHooks.thankYouPageTitle}>
              {t('thank_you_page_pickup.title')}
            </Text>
            <Text className={styles.subheader} typography="p2-l" data-hook={dataHooks.thankYouPageSubtitle}>
              {t('thank_you_page_pickup.subtitle', { name: `${order.contact.firstName} ${order.contact.lastName}` })}
            </Text>
            <div className={styles.details}>
              <div className={styles.detailWrapper}>
                <Text typography="p2-m">{t('thank_you_page_order_no')}</Text>
                <Text typography="p2-m" data-hook={dataHooks.thankYouPageOrderId}>
                  {order.id}
                </Text>
              </div>
              {shouldDisplayDeliveryTime && (
                <div className={classNames(styles.detailWrapper, styles.deliveryTimeWrapper)}>
                  <Text typography="p2-m" className={styles.capitalize}>
                    {t(dispatchType === 'takeout' ? 'checkout_main_pickup_time' : 'thank_you_page_delivery_time')}
                  </Text>
                  <Text typography="p2-m" data-hook={dataHooks.thankYouPageDeliveryTime}>
                    {localDeliveryTime}
                  </Text>
                  {shouldShowOrderPacingWarning ? (
                    <div className={styles.orderPacingNotification}>
                      <SectionNotification
                        data-hook={dataHooks.thankYouPageOrderPacingMessage}
                        type="alert"
                        size="compact"
                      >
                        <SectionNotification.Icon icon={<WarningSignIcon />} />
                        <SectionNotification.Text>
                          {t('checkout_main_order_pacing_ASAP_notification_confirmation', {
                            readyTime: localDeliveryTime,
                          })}
                        </SectionNotification.Text>
                      </SectionNotification>
                    </div>
                  ) : null}
                </div>
              )}
              {shouldDisplayCurbsideInfo && (
                <div className={`${styles.detailWrapper} ${styles.curbsideWrapper}`}>
                  <Text typography="p2-m" className={`${styles.capitalize} ${styles.curbside}`}>
                    {t('thank_you_page_deliverymethod_CurbsidePickup_Label')}
                  </Text>
                  <Text typography="p2-m" data-hook={dataHooks.thankYouPageCurbsideInfo}>
                    {order.delivery.type === 'takeout' && order.delivery?.curbsideAdditionalInformation}
                  </Text>
                </div>
              )}
              {contactlessDineInInfo && (
                <div className={styles.detailWrapper}>
                  <Text className={styles.firstLine} typography="p2-m">
                    {t('thank_you_page_deliverymethod_DineIn_Label')}
                  </Text>
                  <Text className={styles.breakLines} typography="p2-m" data-hook={dataHooks.thankYouPageDineIn}>
                    {contactlessDineInInfo.label}: {contactlessDineInInfo.labelValue}
                  </Text>
                </div>
              )}
              {shouldRenderFulfilledByLine && getFulfilledByLine()}
              {order.delivery.type === 'delivery' && (
                <div className={styles.detailWrapper}>
                  <Text typography="p2-m">{t('thank_you_page_delivery_to')}</Text>
                  <Text
                    className={classNames([order.delivery.address.addressLine2 && styles.twoLines])}
                    typography="p2-m"
                    data-hook={dataHooks.thankYouPageDeliveryAddress}
                  >
                    {order.delivery.address.formatted}
                    {order.delivery.address.addressLine2 && `\n`}
                    {order.delivery.address.addressLine2}
                  </Text>
                </div>
              )}
              <div className={styles.detailWrapper}>
                <Text typography="p2-m">{t('thank_you_page_payment_method')}</Text>
                <PaymentMethod locale={locale} payment={order.payments[0]} />
              </div>
              <div className={styles.detailWrapper}>
                <Text typography="p2-m">{t('thank_you_page_total')}</Text>
                <Text typography="p2-m" data-hook={dataHooks.thankYouPagePrice}>
                  {getDisplayablePrice(order.price, locale, currency)}
                </Text>
              </div>
            </div>
            {dispatchType === 'takeout' && (
              <ThankYouRestaurantDetails restaurantName={orderLocationName} address={restaurant.address} />
            )}
            {isLoyaltyEarnActive && (
              <ThankYouLoyaltyBox
                loyaltyProgramName={loyaltyProgramName || ''}
                isUserLoggedIn={isUserLoggedIn}
                loginMember={loginMember}
                customPointsName={customPointsName}
                pointsToBeEarned={order.loyalty?.estimatedPointsEarned ?? 0}
                pointsBalance={order.loyalty?.estimatedAccountBalance}
                goToLoyaltyPage={goToLoyaltyPage}
                loyaltyRewardId={loyaltyRewardId}
                isRewardInstalled={isRewardInstalled}
              />
            )}
            <Text className={styles.footer} typography="p2-s-secondary">
              {order.created && (
                <span data-hook={dataHooks.thankYouPageSubmittedAt}>
                  {t('thank_you_page_order_submitted', { orderTime: moment(order.created).tz(timezone).format('LT') })}
                  .&nbsp;
                </span>
              )}
              <span data-hook={dataHooks.thankYouPagePhoneNumber}>
                <Trans
                  t={t}
                  i18nKey={'thank_you_page_questions'}
                  values={{
                    phoneNumber: PhoneUtils.displayPhone(restaurant.contact.phone, restaurant.address.countryCode),
                  }}
                  components={[<div className={styles.phoneNumber}>placeholder</div>]}
                />
              </span>
            </Text>
            <Link to="/">
              <Button
                data-hook={dataHooks.thankYouPageHomeButton}
                className={styles.backButton}
                priority={PRIORITY.primary}
                upgrade
                onClick={resetUserOrderSession}
              >
                {t('thank_you_page_cta')}
              </Button>
            </Link>
          </>
        ) : (
          <Spinner />
        )}
      </div>
    </Scroller>
  );
};

export default ThankYou;
