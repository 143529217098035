import { SectionNotification } from 'wix-ui-tpa';
import dataHooks from '../../data-hooks';
import React from 'react';
import Text from '../../core-components/Text';
import { TEXT_BUTTON_PRIORITY, TextButton } from '../TextButton';
import styles from './MemberLoginCTA.scss';
import { Trans, useTranslation } from '@wix/yoshi-flow-editor';

interface MemberLoginCTAProps {
  onLoginClick: () => void;
  onLogoutClick: () => void;
  userEmail?: string;
  isUserLoggedIn?: boolean;
  isMobile?: boolean;
  pointsToBeEarned?: number;
  pointsBalance?: number;
  customPointsName?: string;
  isLoyaltyEarnActive?: boolean;
  dataHook?: string;
  className?: string | boolean;
}

const ColorlessTextButton: React.FC<{ onClick: () => void; children: React.ReactNode }> = ({ onClick, children }) => {
  return (
    <TextButton colorless priority={TEXT_BUTTON_PRIORITY.link} onClick={onClick} data-hook={dataHooks.memberCTALogin}>
      <Text typography="p2-s-colorless">{children}</Text>
    </TextButton>
  );
};

const MemberLoginCTA: React.FC<MemberLoginCTAProps> = ({
  onLoginClick,
  onLogoutClick,
  userEmail,
  isUserLoggedIn,
  isMobile,
  pointsToBeEarned,
  customPointsName,
  pointsBalance,
  isLoyaltyEarnActive,
  dataHook,
  className,
}) => {
  const { t } = useTranslation();
  const Logout = () => (
    <TextButton
      colorless
      priority={TEXT_BUTTON_PRIORITY.link}
      onClick={onLogoutClick}
      data-hook={dataHooks.memberCTALogout}
    >
      <Text typography="p2-s-colorless"> {t('checkout_main_loginout_existingaccount.logout.cta')}</Text>
    </TextButton>
  );
  const content = !isUserLoggedIn ? (
    isLoyaltyEarnActive ? (
      <div className={styles.loyaltyInnerText}>
        <Text typography={'p2-m-colorless'}>
          {customPointsName
            ? t('checkout_main_loyalty_banner_earnpoints_text', {
                number: pointsToBeEarned,
                pointname: customPointsName,
              })
            : t('checkout_main_loyalty_banner_earnpoints_default_name_text', { number: pointsToBeEarned })}
        </Text>
        <Text typography="p2-s-secondary" className={styles.secondLine}>
          <Trans
            t={t}
            i18nKey={'checkout_main_loyalty_banner_login_text'}
            components={[<ColorlessTextButton onClick={onLoginClick}>placeholder</ColorlessTextButton>]}
          />
        </Text>
      </div>
    ) : (
      <div>
        <Text typography="p2-s-colorless">
          <Trans
            t={t}
            i18nKey={'checkout_main_loginout_existingaccount.login.text'}
            components={[<ColorlessTextButton onClick={onLoginClick}>placeholder</ColorlessTextButton>]}
          />
        </Text>
      </div>
    )
  ) : (
    <>
      <Text typography="p2-s-colorless" data-hook={dataHooks.memberCTAEmail}>
        {t('checkout_main_loginout_existingaccount.loggedin.text', { email_address: userEmail })}
        {isLoyaltyEarnActive &&
          ' | ' +
            (customPointsName
              ? t('checkout_main_loyalty_existingpoints_text', { number: pointsBalance, pointname: customPointsName })
              : t('checkout_main_loyalty_existingpoints_default_name_text', { number: pointsBalance }))}
      </Text>
      {isMobile && (
        <>
          <span>{'  '}</span>
          <Logout />
        </>
      )}
    </>
  );

  return (
    <div className={`${styles.wrapper} ${className}`}>
      <SectionNotification data-hook={dataHook || dataHooks.memberCTASection}>
        <SectionNotification.Text>{content}</SectionNotification.Text>
        {isUserLoggedIn && !isMobile && <Logout />}
      </SectionNotification>
    </div>
  );
};

MemberLoginCTA.displayName = 'MemberLoginCTA';

export default MemberLoginCTA;
