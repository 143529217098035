import { createSelector } from 'reselect';
import { AppState } from '../createStore';
import {
  dtlv2_getSupportedDispatchTypes,
  dtlv2_getSupportedDispatchTypesSet,
  getSupportedDispatchTypes,
} from '@wix/restaurants-client-logic';
import { getDeliveryDisplayableMenuSelector } from './menuViewSelector';
import { selectLocations, selectOpenLocations } from './locationsSelector';

export const supportedDispatchTypesSelector = createSelector(
  [
    (state: AppState) => state.session.restaurant.deliveryInfos,
    (state: AppState) => state.session.restaurant.timezone,
    (state: AppState) => getDeliveryDisplayableMenuSelector(state),
    (state: AppState) => state.session.restaurant.orders.asap.disabled,
  ],
  (deliveryInfos, timezone, deliveryDisplayableMenu, isFutureOrdersOnly) => {
    return getSupportedDispatchTypes({
      deliveryInfos,
      timezone,
      deliveryDisplayableMenu,
      getVirtualDispatchTypes: true,
      isFutureOrdersOnly,
    });
  },
);

export const multiLocationSupportedDispatchTypesSelector = createSelector(
  [
    (state: AppState) => selectOpenLocations(state).flatMap((location) => location.deliveryInfos),
    (state: AppState) => state.session.restaurant.timezone,
    (state: AppState) => state.session.restaurant.deliveryInfos,
  ],
  (deliveryInfos, timezone, currentBranchDeliveryInfo) => {
    const supportedDispatchTypes = getSupportedDispatchTypes({
      getVirtualDispatchTypes: true,
      deliveryInfos,
      timezone,
    });
    return supportedDispatchTypes;
  },
);

export const supportedDispatchTypesSelectorV2 = createSelector(
  [(state: AppState) => selectLocations(state)],
  (selectedLocations) => {
    return dtlv2_getSupportedDispatchTypes({
      restaurants: selectedLocations,
    });
  },
);

export const supportedDispatchTypesSetSelectorV2 = createSelector(
  [(state: AppState) => selectLocations(state)],
  (selectedLocations) => {
    return dtlv2_getSupportedDispatchTypesSet({
      restaurants: selectedLocations,
    });
  },
);
