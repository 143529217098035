import { createSelector } from 'reselect';
import Experiments, { ExperimentsBag } from '@wix/wix-experiments';
import { AppState } from '../createStore';

export const selectShouldCalculateTipFromSubtotal = createSelector(
  (state: AppState) => state.session.experiments,
  (experimentsObject) => {
    if (!experimentsObject) {
      return false;
    }
    const experiments = new Experiments({ experiments: experimentsObject });
    return experiments.enabled('specs.restaurants.CalculateTipFromTheSubTotal');
  },
);

const combineExperiments = (state: AppState): ExperimentsBag => ({
  ...state.session.experiments,
  ...state.session.essentialsExperiments,
});

export const selectShouldStickyNavigationInClassicEditor = createSelector(combineExperiments, (experimentsObject) => {
  return experimentsObject['specs.restaurants.olo-client-classic-editor-sticky-navigation'] === 'true';
});
