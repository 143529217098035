import { Address, Contact, Menu, OrderItem, Restaurant } from '@wix/restaurants-client-logic';

export enum DispatchTypeKey {
  Delivery = 'checkout_main_delivery_button',
  Pickup = 'checkout_checkout_main_pickup_button',
  'Dine-in' = 'Checkout_main_deliverymethod_confirmed_DineIn_Label',
}

export const EMPTY_MENU: Menu = Object.freeze({
  sections: [],
  items: [],
  chargesV2: [],
});

export const EMPTY_ADDRESS: Address = Object.freeze({
  formatted: '',
  latLng: {
    lat: 0,
    lng: 0,
  },
});

export const EMPTY_RESTAURANT: Restaurant = Object.freeze({
  type: 'restaurant',
  id: '',
  externalIds: {},
  created: 0,
  modified: 0,
  title: { en_US: '' },
  description: {},
  contact: { email: '', phone: '' },
  address: EMPTY_ADDRESS,
  timezone: '',
  currency: '',
  locale: '',
  locales: [''],
  properties: {},
  media: {},
  distributorId: '',
  openTimes: {},
  deliveryInfos: [],
  paymentTypes: [],
  creditcardsInfo: { networks: [], fields: [], comment: {} },
  deliveriesInfo: { approximateAddresses: 'allow' as const },
  orders: {
    availability: {},
    asap: { preOrderMins: 0 },
    future: { disabled: false, delayMins: { min: 0, max: 0 } },
    properties: {},
  },
  reservations: {
    pendingApproval: true,
    partySize: { min: 0, max: 0 },
    availability: {},
    futureDelayMins: { min: 0, max: 0 },
    heldForMins: 0,
    properties: {},
  },
});

export const EMPTY_CONTACT: Contact = Object.freeze({
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
});

export enum Modals {
  DISH_MODAL = 'DISH_MODAL',
  ORDER_FAILURE_MODAL = 'ORDER_FAILURE_MODAL',
  DISPATCH_SETTINGS_MODAL = 'DISPATCH_SETTINGS_MODAL',
  CART_MODAL = 'CART_MODAL',
  CART_MODAL_CHECKOUT = 'CART_MODAL_CHECKOUT',
  TERMS_AND_CONDITIONS = 'TERMS_AND_CONDITIONS',
  PRIVACY_POLICY = 'PRIVACY_POLICY',
  SMS_MODAL = 'SMS_MODAL',
  CONNECT_PAYMENT_MODAL = 'CONNECT_PAYMENT_MODAL',
  UPGRADE_TO_PREMIUM_MODAL = 'UPGRADE_TO_PREMIUM_MODAL',
  ADDRESS_SELECTION = 'ADDRESS_SELECTION',
  LOGOUT_MODAL = 'LOGOUT_MODAL',
  ADDRESS_INFORMATION_MODAL = 'ADDRESS_INFORMATION_MODAL',
  TEMPLATES_MODAL = 'TEMPLATES_MODAL',
  DINE_IN_CLOSED = 'DINE_IN_CLOSED',
}

export enum RouteUrls {
  INITIAL_VIEW = '/:subRoute?/:sectionSlug?',
  DISH_MODAL = `/dish/:id`,
  DISH_MODAL_EDIT = `/dish/:cartIndex/edit`,
  CHECKOUT_FLOW = '/checkout',
  THANK_YOU = '/thanks',
  DISPATCH_SETTINGS_MODAL = '/dispatch',
}

export const NO_ADDRESS = '[noskip]';
export const DEFAULT_ADDRESS = '500 Terry A Francois Boulevard, San Francisco, CA 94158, United States number = 500';
export const DEFAULT_ADDRESS_SHORT = '500 Terry Francois Street, San Francisco, CA, USA';

export const EMPTY_ORDER_ITEM: OrderItem = { itemId: '', count: 0, price: 0 };

export const SESSION_STORAGE_KEY = 'order-items';
