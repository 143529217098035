import {
  Address,
  Dispatch,
  EMPTY_ADDRESS,
  getMinOrderPriceDetails,
  isAddress,
  Restaurant,
  VirtualDispatchType,
} from '@wix/restaurants-client-logic';
import { useMemo } from 'react';
import { AppState } from '../../state/createStore';
import { cartSummarySelector } from '../../state/selectors/cartSummarySelector';
import { getVirtualDispatchTypeFromDispatch } from '../logic/dispatchLogic';
import { PartialLocation } from '../oloApi';
import { useAppSelector } from './redux';
import { getIdealDeliveryArea } from '../../state/selectors/deliveryAreaSelector';

interface MinOrderPriceOverrides {
  location?: PartialLocation;
  address?: Address;
  dispatchType?: VirtualDispatchType;
  dispatchTime?: number;
}

/**
 * By default dispatch type and address are taken from the checkout.dispatch state
 */
export default function useMinOrderPriceDetails({
  location,
  address,
  dispatchType: overrideDispatchType,
  dispatchTime: overrideDispatchTime,
}: MinOrderPriceOverrides | undefined = {}) {
  const {
    totalOrderPrice,
    restaurant,
    selectedAddressOption,
    dispatchType,
    dispatchTime,
    idealDeliveryArea,
  } = useAppSelector((state) => {
    const { priceComponents } = cartSummarySelector(state);
    const dispatch = overrideDispatchType
      ? ({
          ...state.checkout.dispatch,
          type: overrideDispatchType,
        } as Dispatch)
      : state.checkout.dispatch;
    const deliveryArea = getIdealDeliveryArea({
      address,
      dispatch,
      priceComponents,
      restaurant: (location as Restaurant) ?? state.session.restaurant,
    });
    return {
      totalOrderPrice: priceComponents.subtotal,
      restaurant: (location as Restaurant) ?? state.session.restaurant,
      selectedAddressOption: getAddress(state.checkout, state.addressForm, address),
      dispatchType: overrideDispatchType ?? getVirtualDispatchTypeFromDispatch(state.checkout.dispatch),
      dispatchTime: overrideDispatchTime ?? state.checkout.dispatch.time,
      idealDeliveryArea: deliveryArea?.dispatchInfo,
    };
  });

  const details = useMemo(
    () => {
      return getMinOrderPriceDetails({
        totalOrderPrice,
        restaurant,
        dispatchType,
        dispatchTime,
        selectedAddressOption,
        idealDeliveryArea,
        shouldConsiderDeliveryPartner: true,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      totalOrderPrice,
      restaurant.currentLocationId,
      dispatchType,
      selectedAddressOption?.formatted,
      dispatchTime,
      idealDeliveryArea,
    ],
  );

  return {
    ...details,
    isMinOrderPriceMet: details.amountLeft === 0,
  };
}

function getAddress(
  checkoutState: AppState['checkout'],
  addressFormState: AppState['addressForm'],
  overrideAddress?: Address,
) {
  if (checkoutState.dispatch.type !== 'delivery') {
    return EMPTY_ADDRESS;
  }

  if (overrideAddress && isAddress(overrideAddress)) {
    return overrideAddress;
  } else if (checkoutState.dispatch.address && isAddress(checkoutState.dispatch.address)) {
    return checkoutState.dispatch.address;
  } else if (addressFormState.selectedAddressOption && isAddress(addressFormState.selectedAddressOption)) {
    return addressFormState.selectedAddressOption;
  } else {
    return EMPTY_ADDRESS;
  }
}
