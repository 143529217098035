import React from 'react';
import { AddressOutput, Handler } from 'wix-ui-core/dist/src/clients/GoogleMaps/types';
import { getAtlasClientConstructor, getAddressFromGoogleResult } from '../../../../core/AtlasClient/AtlasClient';
import { EMPTY_ADDRESS } from '../../../../core/constants';
import { AddressInput as CoreAddressInput } from 'wix-ui-core';
import { classes } from './AddressInput.st.css';
import { Tooltip, TooltipSkin, IconButton } from 'wix-ui-tpa';
import CheckXSmall from 'wix-ui-icons-common/on-stage/system/CheckXSmall';
import ErrorIcon from 'wix-ui-icons-common/on-stage/Error';
import dataHooks from '../../data-hooks';
import ClearXSmall from 'wix-ui-icons-common/on-stage/system/ClearXSmall';
import { Address } from '@wix/restaurants-client-logic';
import classNames from 'classnames';
import { AddressLocation } from '@wix/ambassador-wix-atlas-service-web/src/types';
import { useTranslation, useFedopsLogger } from '@wix/yoshi-flow-editor';

export interface AddressInputProps {
  value: string;
  onSelect: (address: Address) => void;
  'data-hook'?: string;
  toolTipText?: string;
  onChange: (value: string) => void;
  placeholder?: string;
  signedInstance: string;
  isMobile: boolean;
  onBlur?: (address?: Address) => void;
  isSuccess?: boolean;
  locale?: string;
  origin?: AddressLocation;
  appendToElement?: Element;
  isViewer: boolean;
}

const AddressInput: React.FC<AddressInputProps> = ({
  value,
  onSelect,
  toolTipText,
  onChange,
  placeholder,
  signedInstance,
  isMobile,
  onBlur = () => {},
  isSuccess,
  locale,
  origin,
  appendToElement,
  isViewer,
  ...rest
}) => {
  const fedopsLogger = useFedopsLogger();
  const { t } = useTranslation();

  const ref = React.createRef<CoreAddressInput>();
  let selectedAddress: Address | undefined;

  const getSuffix = () => {
    return (
      <>
        {value && (
          <div className={classes.clearButtonWrapper}>
            <IconButton
              className={classes.clearButton}
              data-hook={dataHooks.addressInformationInputClear}
              aria-label="Clear"
              onClick={() => {
                onChange('');
                onSelect(EMPTY_ADDRESS);

                if (ref.current) {
                  ref.current.clear();
                }
              }}
              icon={<ClearXSmall />}
            />
            {!toolTipText && !isSuccess && <div className={classes.clearButtonGap} />}
          </div>
        )}
        {toolTipText && (
          <Tooltip
            data-hook={dataHooks.addressInformationInputErrorText}
            content={toolTipText}
            placement="top-end"
            appendTo={'window'}
            zIndex={1020}
            skin={TooltipSkin.Error}
          >
            <div
              data-hook={dataHooks.addressInformationInputErrorIcon}
              style={{ pointerEvents: 'none' }}
              data-error-message={toolTipText}
              className={classes.iconWrapper}
            >
              <ErrorIcon />
            </div>
          </Tooltip>
        )}
        {isSuccess && (
          <div data-hook={dataHooks.addressInformationInputSuccessIcon} className={classes.iconWrapper}>
            <CheckXSmall />
          </div>
        )}
      </>
    );
  };

  const preOnSelect = (raw: AddressOutput) => {
    if (ref.current?.props.value) {
      ref.current.close();
      ref.current.blur();

      const address = getAddressFromGoogleResult(raw);
      onSelect(address);
      selectedAddress = address;
    }
  };

  const className = classNames({
    [classes.root]: true,
    [classes.regular]: !toolTipText && !isSuccess,
    [classes.error]: toolTipText,
    [classes.success]: isSuccess,
  });

  const AtlasClient = fedopsLogger
    ? getAtlasClientConstructor({ signedInstance, locale, origin, fedopsLogger, isViewer })
    : getAtlasClientConstructor({ signedInstance, locale, origin, isViewer });

  return (
    <CoreAddressInput
      key={!appendToElement ? 'addressInput_NotFoundModalYet' : undefined}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          setImmediate(() => {
            if (ref.current) {
              ref.current.blur();
            }
          });
        }
      }}
      id="address-input"
      className={className}
      data-hook={rest['data-hook'] || 'address-input'}
      handler={Handler.places}
      clientId=""
      onChange={(e) => {
        selectedAddress = undefined!;
        onChange(e.target.value);
      }}
      emptyStateMessage={t('checkout_main_delivery_address_noresults')}
      onSelect={(raw) => preOnSelect(raw)}
      Client={AtlasClient as any}
      // @ts-ignore
      converterType="simple"
      value={value}
      fixed
      placeholder={placeholder}
      suffix={getSuffix()}
      ref={ref}
      throttleInterval={400}
      forceSelect
      optionStyle={isMobile ? { fontSize: '14px' } : {}}
      onBlur={() => onBlur(selectedAddress)}
      lang={locale?.split('_')[0]}
      locale={locale}
      appendTo={appendToElement}
      dynamicWidth
      optionsContainerZIndex={1020}
    />
  );
};

AddressInput.displayName = 'AddressInput';
export default AddressInput;
