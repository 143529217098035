import React from 'react';
import { SetDispatchTypePayload } from '../../../../state/checkout/checkout.actions.types';
import styles from './DispatchTypeSelector.scss';
import dataHooks from '../../data-hooks';
import Text from '../../core-components/Text';
import { VirtualDispatchType } from '@wix/restaurants-client-logic';
import Dropdown from '../Dropdown';
import { useTranslation, useBi } from '@wix/yoshi-flow-editor';

export interface DispatchTypeSelectorProps {
  className?: string;
  dispatchType: VirtualDispatchType;
  supportedDispatchTypes: Set<VirtualDispatchType>;
  setDispatchType: (dispatchType: SetDispatchTypePayload) => void;
  isMobile: boolean;
}

const DispatchTypeSelector: React.FC<DispatchTypeSelectorProps> = ({
  className,
  dispatchType,
  setDispatchType,
  supportedDispatchTypes,
  isMobile,
}) => {
  const { t } = useTranslation();
  const biLogger = useBi();

  const _setDispatchType = (dispatchTypePayload: SetDispatchTypePayload) => {
    setDispatchType(dispatchTypePayload);
    biLogger.dispatchSettingsClick({
      clickName: 'dispatch-type',
      clickValue: dispatchTypePayload.dispatchType,
    });
  };

  const getDropdownOptions = () => {
    const options = [];
    if (supportedDispatchTypes.has('delivery')) {
      options.push({ id: 'delivery', value: t('checkout_main_delivery_button'), isSelectable: true });
    }
    if (supportedDispatchTypes.has('takeout')) {
      options.push({ id: 'takeout', value: t('checkout_main_pickup_button'), isSelectable: true });
    }
    if (supportedDispatchTypes.has('dine-in')) {
      options.push({
        id: 'dine-in',
        value: t('Checkout_main_deliverymethod_confirmed_DineIn_Label'),
        isSelectable: true,
      });
    }

    return options;
  };

  const getDispatchTypeTextAndDatahook = (type: VirtualDispatchType) => {
    let text = '',
      datahook = '';
    switch (type) {
      case 'delivery':
        text = t('checkout_main_delivery_button');
        datahook = dataHooks.dispatchSummarySelectorDelivery;
        break;
      case 'takeout':
        text = t('checkout_main_pickup_button');
        datahook = dataHooks.dispatchSummarySelectorTakeout;
        break;
      case 'dine-in':
        text = t('Checkout_main_deliverymethod_confirmed_DineIn_Label');
        datahook = dataHooks.dispatchSummarySelectorDineIn;
        break;
      default:
        text = t('checkout_main_delivery_button');
        datahook = dataHooks.dispatchSummarySelectorDelivery;
        break;
    }

    return { text, datahook };
  };

  const getDispatchTypeTabComponent = (dt: VirtualDispatchType) => {
    const dispatchTypeDetails = getDispatchTypeTextAndDatahook(dt);
    return (
      <button
        className={dispatchType === dt ? styles.selected : ''}
        onClick={() => _setDispatchType({ dispatchType: dt })}
        data-hook={dispatchTypeDetails.datahook}
        autoFocus={dispatchType === dt}
      >
        <Text typography="p2-m">{dispatchTypeDetails.text}</Text>
      </button>
    );
  };

  const shouldUseDropdown = isMobile && supportedDispatchTypes.size > 2;

  return (
    <div
      data-hook="dispatch-type-selector"
      className={`${styles.wrapper} ${className} ${shouldUseDropdown && styles.shouldUseDropdown}`}
    >
      {shouldUseDropdown ? (
        <>
          <Text typography="p2-m" data-hook={dataHooks.dispatchTypeDropdownLabel} className={styles.label}>
            {t('checkout_main_delivery_method')}
          </Text>
          <Dropdown
            key={dispatchType}
            upgrade
            data-hook={dataHooks.dispatchSummaryDispatchTypeOption}
            options={getDropdownOptions()}
            onChange={({ id }: { id: VirtualDispatchType }) => _setDispatchType({ dispatchType: id })}
            initialSelectedId={dispatchType}
            mobileNativeSelect
          />
        </>
      ) : (
        <>
          {supportedDispatchTypes.has('delivery') && getDispatchTypeTabComponent('delivery')}
          {supportedDispatchTypes.has('takeout') && getDispatchTypeTabComponent('takeout')}
          {supportedDispatchTypes.has('dine-in') && getDispatchTypeTabComponent('dine-in')}
        </>
      )}
    </div>
  );
};

DispatchTypeSelector.displayName = 'DispatchTypeSelector';

export default DispatchTypeSelector;
