import React from 'react';
import Spinner from '../Spinner';
import {
  Address,
  extractLocalizedString,
  getLocationsWithTakeout,
  validateAddressForLocations,
  isAddress,
  VirtualDispatchType,
  getLocationsWithDineIn,
} from '@wix/restaurants-client-logic';
import Text from '../../core-components/Text';
import Dropdown from '../Dropdown';
import dataHooks from '../../data-hooks';
import styles from './ChooseLocationDropdown.scss';
import { PartialLocation } from '../../../../core/oloApi';
import { useTranslation, useExperiments } from '@wix/yoshi-flow-editor';

interface Props {
  locations: PartialLocation[];
  onChange(id?: string): void;
  selectedAddressOption?: Address;
  labelText: string;
  isLoading?: boolean;
  filters?: ((location: PartialLocation) => boolean) | VirtualDispatchType;
  className?: string;
  location?: string;
  lockedLocation?: string;
  error?: boolean;
  errorMessage?: string;
  appendToElement?: Element;
  isMobile?: boolean;
  isDineInClosedInCurrentLocation?: boolean;
}

const PickedLocationComponent = (props: { address: string; labelText: string; className?: string }) => (
  <div className={props.className} data-hook={dataHooks.chooseLocation}>
    <Text typography="p2-s" className={styles.label}>
      {props.labelText}
    </Text>
    <Text typography="p2-m" data-hook={dataHooks.chooseLocationAddress}>
      {props.address}
    </Text>
  </div>
);

const getPlaceholder = (filters: ((location: PartialLocation) => boolean) | VirtualDispatchType, t: any) => {
  switch (filters) {
    case 'dine-in':
      return t('delivery_modal_DineIn_Location_PlaceholderText');
    case 'takeout':
      return t('checkout_main_deliverymethod_modal.location.placeholdertext');
    default:
      return '';
  }
};

const getFilteredLocations = ({
  filters,
  locations,
  selectedAddressOption,
  lockedLocation,
}: {
  filters: ((location: PartialLocation) => boolean) | VirtualDispatchType;
  locations: PartialLocation[];
  selectedAddressOption?: Address;
  lockedLocation?: string;
}) => {
  let filteredLocations: PartialLocation[] = [];
  if (typeof filters === 'function') {
    filteredLocations = locations.filter(filters);
  } else {
    if (filters === 'takeout') {
      filteredLocations = getLocationsWithTakeout(locations);
    } else if (filters === 'delivery' && isAddress(selectedAddressOption)) {
      const { hasLocations, locations: validatedLocations } = validateAddressForLocations(
        locations,
        selectedAddressOption,
        { deliveryPartnerFee: undefined, shouldConsiderDeliveryPartner: true },
      );
      filteredLocations = hasLocations && validatedLocations ? validatedLocations : [];
    }
    if (filters === 'dine-in') {
      filteredLocations = getLocationsWithDineIn(locations);
    }
  }

  if (lockedLocation) {
    filteredLocations = locations.filter((l) => l.currentLocationId === lockedLocation);
  }
  return filteredLocations;
};

const ChooseLocationDropdown: React.FC<Props> = ({
  locations,
  onChange,
  labelText,
  selectedAddressOption,
  isLoading,
  filters = () => true,
  className,
  location,
  lockedLocation,
  appendToElement,
  error,
  errorMessage,
  isMobile,
  isDineInClosedInCurrentLocation,
}) => {
  const { t } = useTranslation();
  const { experiments } = useExperiments();
  const fixHorizontalScrollExperimentEnabled = experiments.enabled('specs.restaurants.horizontalScroll');

  if (isLoading) {
    return (
      <div className={styles.spinner} data-hook={dataHooks.chooseLocation}>
        <Spinner diameter={18} isCentered />
      </div>
    );
  }

  const filteredLocations = getFilteredLocations({ filters, locations, selectedAddressOption, lockedLocation });

  const pickerComponent = (
    <div className={className} data-hook={dataHooks.chooseLocation}>
      <Text tagName="label" typography="p2-s" className={styles.label}>
        {labelText}
      </Text>
      <Dropdown
        key={!appendToElement ? 'chooseLocation_NotFoundModalYet' : undefined}
        upgrade
        fixed
        error={error}
        errorMessage={errorMessage}
        optionsContainerZIndex={1020}
        appendTo={appendToElement}
        placeholder={getPlaceholder(filters, t)}
        data-hook={dataHooks.chooseLocationDropdown}
        options={filteredLocations.map((loc) => {
          const val = extractLocalizedString(loc.locationName ?? {}, loc.locale);
          return {
            id: loc.currentLocationId,
            value: !fixHorizontalScrollExperimentEnabled && isMobile ? val + ', ' + loc.address.formatted : val,
            subtitle: loc.address.formatted,
            isSelectable: true,
          };
        })}
        initialSelectedId={location}
        onChange={({ id }) => {
          onChange(id);
        }}
        mobileNativeSelect
      />
    </div>
  );

  switch (filteredLocations.length) {
    case 0:
      return null;
    case 1:
      if (filters === 'delivery') {
        return null;
      }
      if (filters === 'dine-in' && isDineInClosedInCurrentLocation) {
        return pickerComponent;
      } else {
        return (
          <PickedLocationComponent
            address={filteredLocations[0].address.formatted}
            labelText={labelText}
            className={className}
          />
        );
      }
      break;
    default:
      return pickerComponent;
  }
};

export default ChooseLocationDropdown;
