import React from 'react';
import ModalLayoutBasic from '../ModalLayoutBasic';
import Text from '../../core-components/Text';
import dataHooks from '../../data-hooks';
import { OrderFailureType, VirtualDispatchType } from '@wix/restaurants-client-logic';
import { SetCheckoutStepPayload } from '../../../../state/checkout/checkout.actions.types';
import { IPaymentMethod } from '@wix/cashier-payments-widget/dist/src/cashier-payments';
import { LocaldeliveryOrderErrorCode as EstimateTpeErrorCode } from '@wix/ambassador-restaurants-local-delivery/http';
import { useTranslation } from '@wix/yoshi-flow-editor';
import ModalLayoutBasicFooter from '../ModalLayoutBasicFooter';
import styles from './DineInClosedModal.scss';
import { SetIsLocationPickedPayload } from '../../../../state/session/session.actions.types';

export interface DineInClosedModalProps {
  onRequestClose: () => void;
  resetErrors: () => void;
  errorType?: OrderFailureType;
  errorCode?: number;
  estimateTpaErrorCode?: EstimateTpeErrorCode;
  isCouponError?: boolean;
  setCheckoutStep: (payload: SetCheckoutStepPayload) => void;
  hasFutureOrders?: boolean;
  restaurantPhone?: string;
  dispatchType: VirtualDispatchType;
  loyaltyPointsName?: string;
  paymentMethods: IPaymentMethod[];
  saveStateToSessionStorage: () => void;
  removeDispatchTypeFromURL: Function;
  setIsLocationPicked: (payload: SetIsLocationPickedPayload) => void;
}

const DineInClosedModal: React.FC<DineInClosedModalProps> = ({
  onRequestClose,
  removeDispatchTypeFromURL,
  setIsLocationPicked,
}) => {
  const { t } = useTranslation();

  const onCloseModal = () => {
    removeDispatchTypeFromURL();
    setIsLocationPicked({ value: false });
    onRequestClose();
  };

  const header = (
    <Text typography="header-m" data-hook={dataHooks.orderFailureModalTitle}>
      {t('online_ordering_dinein_unavailable_error_title')}
    </Text>
  );

  const footer = (
    <ModalLayoutBasicFooter
      primaryButtonContent={t('online_ordering_dinein_unavailable_error_cta')}
      onOkClick={onCloseModal}
      fullWidthMobile
      centered
    />
  );

  return (
    <ModalLayoutBasic
      header={header}
      onCloseClick={onCloseModal}
      footer={footer}
      data-hook={dataHooks.dineInClosedModal}
    >
      <div className={styles.wrapper}>
        <Text typography={'p2-m'}>{t('online_ordering_dinein_unavailable_error_text')}</Text>
      </div>
    </ModalLayoutBasic>
  );
};

DineInClosedModal.displayName = 'DineInClosedModal';

export default DineInClosedModal;
