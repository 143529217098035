import React, { useContext } from 'react';
import { scroller } from 'react-scroll';
import { PaymentsWidget } from '@wix/cashier-payments-widget/lazy';
import countryCodeIso3Map from '@wix/wixrest-utils/dist/countryCodeIso3Map';
import { ViewModeOOI } from '@wix/cashier-payments-widget/dist/src/types/ViewMode';
import {
  continuePayment,
  createMandatoryFieldsObject,
  getCashierApi,
  setCashierApi,
} from '../../../../core/logic/cashierLogic';
import { SetCashierPaymentDonePayload } from '../../../../state/checkout/checkout.actions.types';
import dataHooks from '../../data-hooks';
import { EMPTY_ADDRESS } from '../../../../core/constants';
import { Address, Contact, DispatchType, Instance } from '@wix/restaurants-client-logic';
import HostStyleContext from '../HostStyleContext';
import { useController } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import { componentSettings } from '../../componentSettings';

export interface CashierProps {
  instance: Instance;
  language: string;
  paymentMethod: string | undefined;
  address?: Address;
  restaurantAddress: Address;
  contact: Contact;
  dispatchType: DispatchType;
  hidden?: boolean;
  cashierToken?: string;
  paymentMethodChanged?: (paymentMethodId: string, paymentMethodTitle: string) => void;
  setCashierPaymentDone: (payload: SetCashierPaymentDonePayload) => void;
  signedInstance: string;
  viewMode: ViewModeOOI;
  isMobile: boolean;
  isUserLoggedIn: boolean;
  currency: string;
  amount: string;
  isMAInstalled?: boolean;
  onPaymentStart: (method: string) => void;
  onPaymentComplete: (method: string, result: any) => void;
  allowManualPayment?: boolean;
}

const Cashier: React.FC<CashierProps> = ({
  instance,
  language,
  paymentMethod,
  address,
  restaurantAddress,
  contact,
  dispatchType,
  hidden,
  cashierToken,
  paymentMethodChanged,
  setCashierPaymentDone,
  signedInstance,
  viewMode,
  isUserLoggedIn,
  isMobile,
  currency,
  amount,
  isMAInstalled,
  onPaymentStart,
  onPaymentComplete,
  allowManualPayment = true,
}) => {
  const getMandatoryFields = React.useCallback(
    () =>
      createMandatoryFieldsObject({
        address,
        contact,
        dispatchType,
        paymentMethod,
      }),
    [address, contact, dispatchType, paymentMethod],
  );

  const getMandatoryFieldsWithoutAddress = () => {
    return createMandatoryFieldsObject({
      address: EMPTY_ADDRESS,
      contact,
      dispatchType,
      paymentMethod,
    });
  };

  React.useEffect(() => {
    let isSubscribed = true;

    if (cashierToken) {
      continuePayment(cashierToken)
        .then((res) => {
          if (res && isSubscribed) {
            setCashierPaymentDone({ paymentResult: res });
          }
        })
        .catch(function (paymentResult) {
          setCashierPaymentDone({ paymentResult });
        });
    }

    return () => {
      isSubscribed = false;
    };
  }, [cashierToken, setCashierPaymentDone]);

  const hostStyle = useContext(HostStyleContext);

  const settings = useSettings();

  const isSaveCCEnabled = settings.get(componentSettings.hasMembersAreaIntegration);

  const configuration = {
    appId: instance.appDefId,
    appInstanceId: instance.instanceId,
    msid: instance.metaSiteId,
    visitorId: instance.aid,
    siteOwnerId: instance.siteOwnerId,
    locale: language,
    country: address?.countryCode
      ? countryCodeIso3Map[address?.countryCode]
      : restaurantAddress.countryCode && countryCodeIso3Map[restaurantAddress.countryCode],
    viewMode,
    appInstance: signedInstance,
    isSignedInUser: isMAInstalled && isUserLoggedIn && isSaveCCEnabled,
    currency,
    amount,
  };

  const style = hidden ? { display: 'none' } : {};

  const controller = useController();

  return (
    <div data-hook={dataHooks.cashierWidget} data-is-hidden={hidden} style={style}>
      <PaymentsWidget
        isSaveCCEnabled={isSaveCCEnabled}
        isWidgetVisible={!hidden}
        locale={language}
        deviceType={isMobile ? 'mobile' : 'desktop'}
        configuration={configuration}
        externalSubmitButton
        allowManualPayment={allowManualPayment}
        shouldApplySiteStyles={Boolean(hostStyle)}
        creditCardPaymentInNewTab={false}
        onApiInit={(api) => setCashierApi(api)}
        mandatoryFields={getMandatoryFieldsWithoutAddress()}
        mandatoryFieldsPrefilled={getMandatoryFields()}
        paymentMethodChanged={(paymentMethodId: string) => {
          if (!hidden) {
            scroller.scrollTo(isMobile ? 'checkout-payments' : 'contact-information', {
              smooth: 'easeInOutCubic',
              duration: 200,
            });
          }

          getCashierApi().then((api) => {
            // @ts-ignore
            const paymentMethodTitle = api.activePaymentMethod?.name;
            paymentMethodChanged && paymentMethodChanged(paymentMethodId, paymentMethodTitle);
          });
        }}
        creditCardChanged={() => {
          getCashierApi().then((api) => {
            // @ts-ignore
            const paymentMethodTitle = api.activePaymentMethod?.name;
            paymentMethodChanged && paymentMethodChanged('creditCard', paymentMethodTitle);
          });
        }}
        walletPaymentInNewTab={false}
        siteStyles={hostStyle}
        // @ts-ignore
        revalidateAppInstance={controller.revalidateSession}
        onPaymentStart={onPaymentStart}
        onPaymentComplete={onPaymentComplete}
      />
    </div>
  );
};

Cashier.displayName = 'Cashier';

export default Cashier;
